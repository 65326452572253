<template>
  <main id="site-wrapper">
    <section class="section">
      <div class="container-fluid">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Dashboard</a>
          </li>
          <li class="breadcrumb-item active">
            Branding
          </li>
        </ol>
        <div class="section-header">
          <div class="section-header-title">
            <router-link
              class="section-header-back"
              :to="{name: 'dashboard'}"
            >
              <i class="fas fa-arrow-circle-left" />
            </router-link>
            <h1>Personal branding</h1>
          </div>
        </div>
        <div class="row row-has-panel">
          <div class="col-lg-12">
            <div class="section-panel">
              <div class="section-panel-header">
                <div class="section-panel-header-title">
                  <h3>Brand info</h3>
                </div>
                <div
                  v-if="canEditPersonalBranding"
                  class="section-panel-header-action"
                >
                  <a
                    v-if="statusEdit"
                    href="#"
                    class="btn btn-link"
                    @click.prevent="discardEdit"
                  >
                    <icon-cross class="mr-2 d-flex" /> Cancel &amp; Discard
                  </a>
                  <a
                    v-else
                    href="#"
                    class="btn btn-link"
                    @click.prevent="statusEdit = true"
                  >
                    <icon-edit class="mr-2 d-flex" />Edit
                  </a>
                </div>
                <div
                  v-else
                  class="section-panel-header-action"
                >
                  <a
                    class="btn btn-link cursor-pointer"
                    @click="$emit('show-premium-feature-popup')"
                  >
                    <icon-locked class="mr-2 d-flex" />Edit
                  </a>
                </div>
              </div>
              <div class="section-panel-body">
                <form class="form-horizontal">
                  <div class="row">
                    <div class="col-12">
                      <h6 class="mb-1">
                        What type of calls do you have?
                      </h6>
                      <div class="form-group">
                        <div class="custom-checkbox">
                          <input
                            id="type-meeting"
                            type="checkbox"
                            :checked="call_type==='meetings'"
                            :disabled="!statusEdit"
                            @click="changeCallType('meetings')"
                          >
                          <label for="type-meeting">Meetings</label>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="custom-checkbox mb-1">
                          <input
                            id="type-other"
                            type="checkbox"
                            :checked="call_type==='other'"
                            :disabled="!statusEdit"
                            @click="changeCallType('other')"
                          >
                          <label for="type-other">Other</label>
                        </div>
                        <input
                          v-if="call_type === 'other'"
                          v-model="custom_call_type"
                          type="text"
                          class="form-control"
                          placeholder="E.g. consulting room"
                          :disabled="!statusEdit"
                        >
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="section-panel-header">
                <div class="section-panel-header-title">
                  <h3>Visual materials</h3>
                </div>
              </div>
              <div class="section-panel-body">
                <form class="form-horizontal">
                  <div class="row">
                    <div class="col-lg flex-lg-grow-0 mb-1 mb-lg-0">
                      <div class="form-group-upload">
                        <label
                          v-if="logo"
                          for="logo"
                          class="real-image"
                        >
                          <img
                            id="logo_image"
                            alt="Upload"
                            :src="logo"
                          >
                        </label>
                        <label
                          v-else
                          for="logo"
                          class="place-holder"
                        >
                          <span class="p-3">
                            <icon-upload />
                            <!-- <span>Drag &amp; Drop file here</span> -->
                          </span>
                        </label>
                        <input
                          id="logo"
                          name="logo"
                          type="file"
                          :disabled="!statusEdit"
                          @change="onFileSelected"
                        >
                      </div>
                    </div>
                    <div class="col-lg align-self-start">
                      <div class="form-group-logo-content">
                        <h4>Logo</h4>
                        <p>
                          Visible on the welcome screen for all meetings, during invite process and other places.
                        </p>
                        <label
                          v-if="logo && statusEdit"
                          class="btn btn-outline"
                          @click.prevent="confirmDeleteImage('logo')"
                        ><i class="far fa-trash-alt text-danger" /></label>
                        <label
                          v-if="statusEdit"
                          for="logo"
                          class="btn btn-outline"
                        >Choose</label>
                      </div>
                    </div>
                    <div class="col-lg form-group-recommended">
                      <h5>Recommendation</h5>
                      <img
                        v-svg-inline
                        src="@/assets/img/logo_mockup.svg"
                        class="logo stroke"
                      >
                      <p>The logo asset should be in a landscape aspect, with whitespace around the brand elements</p>
                      <p>Dimensions: <strong>width of 250px</strong> and a height greater than 30px</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg justify-content-end align-self-end">
                      <div v-if="statusEdit">
                        <button
                          type="submit"
                          class="btn btn-md btn-primary float-right"
                          @click.prevent="updatePersonalBrand()"
                        >
                          Save
                        </button>
                      </div>
                      <span
                        v-for="(error, index) in errors.logo"
                        :key="index"
                        class="form-error-message"
                      >
                        {{ error }}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="canEditPersonalBranding"
          class="text-right mt-1"
        >
          <button
            class="btn btn-gray btn-sm"
            @click="isShowConfirmRestoreDefaults = true"
          >
            Restore default
          </button>
        </div>
      </div>
    </section>

    <confirm-dialog
      v-if="isShowConfirmDialog"
      title="Are you sure you want to delete this image?"
      description="You will not be able to recover it later"
      @no="cancelDeleteImage"
      @yes="deleteImage"
    />
    <confirm-dialog
      v-if="isShowConfirmRestoreDefaults"
      title="Confirmation"
      :description="'Are you sure you want to restore default branding? This will restore logo and type of calls.'"
      @no="isShowConfirmRestoreDefaults = false"
      @yes="toggleRestoreDefaults"
    />
  </main>
</template>
<script>
import {userStore} from '@/store/__STORE_user';
import {mainStore} from '@/store/__STORE_main';
import PlanMixin from '@/mixins/PlanMixin';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import IconEdit from '@/components/icons/IconEdit.vue';
import IconCross from '@/components/icons/IconCross.vue';
import IconUpload from '@/components/icons/IconUpload.vue';
import IconLocked from '@/components/icons/IconLocked.vue';
import axios from 'axios';
export default {
  components: {
    ConfirmDialog,
    IconEdit,
    IconCross,
    IconUpload,
    IconLocked,
  },
  mixins: [PlanMixin],
  data() {
    return {
      statusEdit: false,
      logo: '',
      selectedFile: '',
      errors: [],
      call_type: '',
      custom_call_type: '',
      isShowConfirmDialog: false,
      imageToBeDeleted: '',
      isShowConfirmRestoreDefaults: false,
    };
  },
  computed: {
    brand() {
      return userStore.personalBrand;
    },
  },
  created() {
    userStore.getPersonalBrand()
        .then(() => {
          this.initBrand();
        });
  },
  methods: {
    initBrand() {
      this.logo = this.brand.logo;
      this.selectedFile = '';
      this.call_type = this.brand.call_type;
      this.custom_call_type = this.brand.custom_call_type;
    },
    discardEdit() {
      this.statusEdit = false;
      this.initBrand();
      this.errors = [];
    },
    updatePersonalBrand() {
      const vm = this;
      const param = new FormData();
      vm.errors = [];
      param.append('call_type', vm.call_type);
      param.append('custom_call_type', vm.custom_call_type);
      if (vm.selectedFile) {
        param.append('logo', vm.selectedFile, vm.selectedFile ? vm.selectedFile.name : '');
      }
      userStore.updatePersonalBrand(param)
          .then(() => {
            userStore.getUserProfile();
            vm.$toast.success('Brand updated successfully!');
            vm.statusEdit = false;
            vm.selectedFile = '';
            vm.errors = [];
          })
          .catch((err) => {
            try {
              const data= err.response.data;
              vm.$toast.error(data.message);

              if (data.errors) {
                vm.errors = data.errors;
              }
            } catch {
              return;
            }
          });
    },
    confirmDeleteImage(image) {
      this.imageToBeDeleted = image;
      this.isShowConfirmDialog = true;
    },
    cancelDeleteImage() {
      this.isShowConfirmDialog = false;
      this.imageToBeDeleted = '';
    },
    deleteImage() {
      this.errors = [];
      mainStore.setLoading();
      axios({url: '/personal-brand-image/'+this.imageToBeDeleted, method: 'DELETE'})
          .then(() => {
            mainStore.clearStatus();
            userStore.getUserProfile();
            userStore.getPersonalBrand()
                .then(() => {
                  this.isShowConfirmDialog = false;
                  this.initBrand();
                  this.$toast.success('Image deleted');
                });
          })
          .catch((err) => {
            mainStore.clearStatus();
            this.$toast.error(err);
          });
    },
    onFileSelected(event) {
      this.selectedFile = event.target.files[0];
      this.logo = URL.createObjectURL(this.selectedFile);
    },
    changeCallType(curType) {
      if (this.call_type === curType) {
        this.call_type = '';
      } else {
        this.call_type = curType;
      }

      if (this.call_type !== 'other') {
        this.custom_call_type = '';
      }
    },
    toggleRestoreDefaults() {
      mainStore.setLoading();
      axios.post('/personal-brand/restore-default-branding')
          .then(async () => {
            await userStore.getPersonalBrand();
            this.initBrand();
            await userStore.getUserProfile();
            mainStore.clearStatus();
            this.statusEdit = false;
            this.isShowConfirmRestoreDefaults = false;
          })
          .catch((error) => {
            mainStore.clearStatus();
            this.$toast.error('Error.');
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  #logo_image{
    height: 100%;
    object-fit: contain;
    width: 100%;
  }

  h6 {
    font-size: 1.98rem;
  }

  .form-group-logo-content {
    flex: 1 0;
    max-width: 100%;
  }

  .form-group-recommended {
    flex: 1 1;
    h5 {
      font-weight: 400;
      white-space: nowrap;
    }

     ::v-deep svg {
      margin: 1rem 0;
      max-width: 200px;
      width: 70%;
    }

    p {
      font-size: 1.3rem;
    }
  }
</style>
